import { BasePerimeter } from '@argon/iam/perimeters'
import { ROLES } from '@argon/iam/utils'

export default new BasePerimeter({
  purpose: 'deal',
  can: {
    read(deal) {
      return this.hasAction(deal, 'read')
    },
    update(deal) {
      return this.hasAction(deal, 'update')
    },
    destroy(deal) {
      return this.hasAction(deal, 'delete')
    },
    manage() {
      return this.hasPermission('deal:manage')
    },
    list() {
      return this.hasPermission('deal:read')
    },
    create() {
      return this.hasPermission('deal:create')
    },
    administrate() {
      return this.isAdmin()
    },
    upToBroker() {
      let org = this.child.getters['organizations/activeOrg']
      return this.isLevelOrBelow(org, ROLES.COLLABORATOR)
    },
    isAdvisor() {
      let org = this.child.getters['organizations/activeOrg']
      return this.isLevel(org, ROLES.MEMBER)
    },
    isBroker() {
      let org = this.child.getters['organizations/activeOrg']
      return this.isLevel(org, ROLES.COLLABORATOR)
    },
    atLeastAdvisor() {
      let org = this.child.getters['organizations/activeOrg']
      return this.isLevelOrAbove(org, ROLES.MEMBER)
    },
    atLeastManager() {
      let org = this.child.getters['organizations/activeOrg']
      return this.isLevelOrAbove(org, ROLES.MANAGER)
    }
  }
})
