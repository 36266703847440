<template>
  <VModalWidget :submit="submit" :dismiss="dismiss" :submitting="submitting" :submit-title="$t('labels.save')">
    <template v-slot:title>{{ $t('labels.state') }}</template>

    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-form>
        <v-row>
          <v-col cols="12">
            <StatusField v-model="form.status" :errors="statusErrors" />
          </v-col>
          <v-col cols="12"> <RefusalField v-model="form.meta.refusal" /> </v-col>
          <template v-if="$deal.isAllowed('atLeastManager')">
            <v-col cols="12" v-if="boardRef === 'life-individual'">
              <DateField
                v-model="form.meta.dates.ongoing_sold_on"
                :timezone="timezone"
                :label="$t('labels.ongoingSoldOn')"
                clearable
              />
            </v-col>
            <v-col cols="12" v-if="boardRef === 'life-individual'">
              <DateField
                v-model="form.meta.dates.finalized_paid_on"
                :timezone="timezone"
                :label="$t('labels.finalizedPaidOn')"
                clearable
              />
            </v-col>
            <v-col cols="12" v-if="boardRef === 'life-group'">
              <DateField
                v-model="form.meta.dates.ongoing_bought_on"
                :timezone="timezone"
                :label="$t('labels.ongoingBoughtOn')"
                clearable
              />
            </v-col>
            <v-col cols="12" v-if="boardRef === 'life-group'">
              <DateField
                v-model="form.meta.dates.finalized_delivered_on"
                :timezone="timezone"
                :label="$t('labels.finalizedDeliveredOn')"
                clearable
              />
            </v-col>
          </template>
          <v-col cols="6">
            <v-checkbox v-model="form.meta.sold_by_phone" :label="$t('labels.byPhone')" />
          </v-col>
        </v-row>
      </v-form>
    </template>
  </VModalWidget>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import pick from 'lodash/pick'
import { VModalWidget, BaseForm, FormErrors, DateField } from '@argon/app/components'
import { required } from 'vuelidate/lib/validators'
import { StatusField } from '@/statuses/components'
import { RefusalField } from '@/refusals/components'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { NAMESPACE } from '@argon/evo/entities/store'
import dealPerimeter from '../perimeters'
import DealInfoModalMixin from '../mixins/DealInfoModalMixin'

export default {
  name: 'DealStateModal',
  mixins: [DealInfoModalMixin],
  perimeters: [dealPerimeter],
  extends: BaseForm,
  components: {
    VModalWidget,
    FormErrors,
    StatusField,
    RefusalField,
    DateField
  },
  validations: {
    form: {
      blocker: {},
      status: { required }
    }
  },
  data: () => ({
    form: {
      status: null,
      meta: {
        refusal: null,
        sold_by_phone: false,
        dates: {}
      }
    },
    submitting: false
  }),
  computed: {
    ...mapGetters(ME_NAMESPACE, ['timezone']),
    statusErrors() {
      const errors = []
      this.getServerErrors('status', errors)
      if (!this.$v.form.status.$dirty) return errors
      !this.$v.form.status.required && errors.push(this.$t('required'))
      return errors
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['patchEntity']),
    submit() {
      if (this.$v.form.$invalid) {
        return this.$v.form.$touch()
      }
      this.submitting = true
      let data = pick(this.form, ['status', 'meta'])
      let payload = { url: this.boardItem.entity.uris.self, data, proxy: this.proxy }
      this.patchEntity(payload)
        .then(() => {
          this.dismiss()
        })
        .catch(this.setServerErrors)
        .finally(() => {
          this.submitting = false
        })
    }
  },
  created() {
    this.form = {
      status: this.boardItem.entity.status,
      meta: {
        sold_by_phone: this.boardItem.entity.meta.sold_by_phone,
        refusal: this.boardItem.entity.meta.refusal,
        dates: pick(this.boardItem.entity.meta.dates, [
          'ongoing_sold_on',
          'ongoing_bought_on',
          'finalized_paid_on',
          'finalized_delivered_on'
        ])
      }
    }
  }
}
</script>
