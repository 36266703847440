import { mapState, mapActions } from 'vuex'
import { NAMESPACE } from '@argon/evo/entities/store'
import { Modal } from '@argon/app/mixins'

export default {
  name: 'DealInfoModalMixin',
  mixins: [Modal],
  props: {
    entityId: { type: [String, Number], required: true },
    boardRef: { type: [String, Number], required: true }
  },
  data: () => ({
    form: {},
    submitting: false
  }),
  computed: {
    ...mapState(NAMESPACE, ['boardItem']),
    baseURL() {
      return this.boardItem.entity.uris.self
    },
    entityURL() {
      return `/boards/${this.boardRef}/items/${this.entityId}`
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['getBoardItem', 'patchEntity']),
    dismiss() {
      if (this.prevRoute && this.prevRoute.name) {
        this.$router.push(this.prevRoute)
      } else {
        this.$router.push({
          name: 'boardCard',
          params: { boardRef: this.boardRef, entityId: this.entityId }
        })
      }
    }
  }
}
